import React from "react";
import { Typography, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { TextDecrypt } from "./TextDecrypt";


const useStyles = makeStyles((theme) => ({
    main: {
        marginTop: "auto",
        marginBottom: "auto",
        "@media (max-width: 768px)": {
            marginLeft: theme.spacing(4),
        },
    },
}));

export const Content = () => {
    const classes = useStyles();

    return (
        <Container component="main" className={`${classes.main}`} maxWidth="sm">
            <Typography variant="h2" component="h1" gutterBottom>
                <TextDecrypt text={`HWLthink`} />
            </Typography>
            <Typography variant="h5" component="h2" gutterBottom>
                <TextDecrypt text={`workflow design,`} /><br />
                <TextDecrypt text={`process automation,`} /><br />
                <TextDecrypt text={`custom applications,`} /><br />
                <TextDecrypt text={`best in class product selection`} />
                
                
            </Typography>
            <Typography variant="body2" component="h2" gutterBottom>
                <TextDecrypt text={`*new clients by referral only at this time`} />
            </Typography>
        </Container>
    );
};
