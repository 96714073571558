import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    svgHover: {
        fill: theme.palette.foreground.default,
        "&:hover": {
            fill: theme.palette.primary.main,
        },
        transition: "all 0.5s ease",
    },
}));

export const Logo = () => {
    const classes = useStyles();

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 400 400"
            className={classes.svgHover}
            transform="scale(0.8)"
        >
      

        <path d="M816.81,480.91h286.38V201.47a41.44,41.44,0,0,1,.1-5c.84-6.8,3.87-9.24,11.73-9.27,15.5-.07,31,0,46.5,0h25c10,0,12.63,2.71,12.63,12.81V702.43q0,81.48,0,163c0,10-2.59,12.58-12.8,12.58h-70c-10.6,0-13.16-2.61-13.16-13.35V568.85H816.81V864.49c0,11-2.49,13.49-13.52,13.49H732.8c-9.1,0-11.94-2.84-11.94-11.94V199.63c0-9.69,2.75-12.43,12.49-12.44h70.49c10.16,0,13,2.85,13,13.14V480.91Z"
            transform="translate(-280 -70) scale(0.5)" 
        />
        <path d="M1001.62,364.14q2.68-17.89,5.37-35.8,6-40,12-80c1.07-7,3.6-9.2,10.59-9.29,5.5-.08,11-.13,16.5,0,7.47.19,10.9,4.32,9.75,11.74q-14.22,92-28.5,184.07c-.51,3.29-.9,6.6-1.43,9.88-1,6.34-3.53,8.91-9.81,9.16-7,.28-14,.25-21,0-4.77-.16-7.88-2.56-9.26-7.53-5.12-18.44-10.49-36.8-15.77-55.2-.69-2.39-1.43-4.76-2.51-8.35-3.06,10.7-5.81,20.28-8.54,29.88-3.14,11-6.42,22-9.3,33.15-1.4,5.4-4.72,7.93-9.93,8.07-7.16.19-14.34.21-21.5,0-5.73-.17-8.26-2.63-9.2-8.56q-7.45-47.36-14.82-94.72-7.79-50.08-15.47-100.19c-1.08-6.94,1.84-10.94,9-11.32,6.82-.36,13.67-.29,20.49,0,5.43.21,7.82,2.68,8.75,8.47q7.56,47.34,15,94.69a139.78,139.78,0,0,0,4.63,21.4c1.78-6.47,3.59-12.94,5.33-19.42C945,332.85,948,321.43,951.11,310c1.71-6.37,4-8.24,10.54-8.46,4.66-.15,9.33-.13,14,0,7.17.2,9.35,2,11.11,9q6.27,24.68,12.5,49.35c.37,1.43.85,2.82,1.28,4.23Z"
            transform="translate(-280 -70) scale(0.5)" 
        />
        <path d="M939,792.78h64.55v-32.6c0-15.65,1-16.67,16.78-16.63,4.15,0,8.32.07,12.46.38,4.46.33,6.89,3,7.35,7.4a45.91,45.91,0,0,1,.33,5q0,30.74,0,61.46c0,9.28-3,12.21-12.26,12.22q-58.22,0-116.44,0c-9.28,0-12.22-2.94-12.22-12.25q0-95.19,0-190.39c0-9.35,2.92-12.27,12.17-12.31,4.66,0,9.33,0,14,0,11,0,13.28,2.24,13.28,13.05V792.78Z"
            transform="translate(-280 -70) scale(0.5)" 
        />
        </svg>
    );
};
