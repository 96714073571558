import React from "react";
import Helmet from "react-helmet";
//import Resume from "../settings/resume.json";
import Settings from "../settings/settings.json";

export const HelmetMeta = () => {
    return (
        <Helmet>
            <meta name="theme-color" content={Settings.colors.primary} />
            <title>HWLthink</title>
            <meta name="author" content="HWLthink" />
            <meta name="description" content="HWLthink provides process automation, workflow design, custom applications development, and best in class product selection solutions to help businesses and creatives maximize the output and efficiency of their daily tasks" />
            <meta name="keywords" content="hwlthink, workflow design, process automation, custom applications, product selection, product review, content creation workflows, best in class" />
        </Helmet>
    );
};
