import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Tooltip, Zoom, Link } from '@material-ui/core';
import { TextDecrypt } from '../content/TextDecrypt';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';



const useStyles = makeStyles((theme) => ({
  footerText: {
    position: 'absolute',
    bottom: theme.spacing(6),
    left: theme.spacing(6),
    '&:hover': {
      color: theme.palette.primary.main,
    },
    transition: 'all 0.5s ease',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
}));

export const FooterText = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
<ClickAwayListener onClickAway={handleTooltipClose}>
 <Link
      color='inherit'
      underline='none'
      href='#'
      target='_self'
      rel='noopener noreferrer'
      className={classes.footerText}
    >
    <Tooltip
        title="Client: Turn on your portal access browser extension to populate this link."
        placement='right'
        TransitionComponent={Zoom}
        PopperProps={{
          disablePortal: true,
        }}
        onClose={handleTooltipClose}
        open={open}
      >
      <Button onClick={handleTooltipOpen}>
      <Typography variant='body2'>
        <TextDecrypt text={'client portal'} />
      </Typography>
      </Button>
      </Tooltip>
    </Link>
     </ClickAwayListener>
  );
};
